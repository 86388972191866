@import url('https://fonts.googleapis.com/css2?family=Allura&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Sorts+Mill+Goudy:ital@0;1&display=swap');

/* ----- GENERAL ----- */

.App {
	text-align: center;
	color: #202020;
}

.screen-size-message {
	display: none;
}

@media screen and (min-width: 600px) {
	.screen-size-message {
		display: block;
		height: 100vh;
		align-content: center;
		max-width: 600px;
		margin: 0 auto;
	}

	.monogram {
		width: 200px;
	}
}

.all-main-content {
	display: none;
	margin-top: 80px;
}

@media screen and (max-width: 600px) {
	.all-main-content {
		display: block;
	}
}

h1 {
	font: 500 40px 'Sorts Mill Goudy', serif;
	text-transform: uppercase;
	line-height: 40px;
}

h3 {
	font: 400 40px 'Sorts Mill Goudy', serif;
	text-transform: uppercase;
	margin: 0;
}

h4 {
	font: 400 40px 'Allura', cursive;
	margin: 0;
}

h5 {
	font: 500 20px 'Sorts Mill Goudy', serif;
	text-transform: uppercase;
}

h6 {
	text-transform: uppercase;
	font: 600 14px 'Lato', sans-serif;
	letter-spacing: 5px;
}

p {
	font: 300 18px 'Lato', sans-serif;
	margin: 0;
}

a.button-link {
	color: #efefef;
	text-decoration: none;
	font: 500 20px 'Sorts Mill Goudy', serif;
	background-color: #cb977f;
	padding: 15px 40px;
	border-radius: 5px;
	text-transform: uppercase;
}

.cursive {
	font: 400 50px 'Allura', cursive;
	text-transform: none;
}

.heading {
	display: flex;
	align-items: center;
}

.line {
	height: 1px;
	width: 100%;
	margin: 0 30px;
	background-color: #edd5bd;
}

.details-section p.no-spacing {
	letter-spacing: 0px;
}

/* ----- HEADER ----- */

header {
	background-color: #f8f8f8;
	position: fixed;
	top: 0;
	width: 100vw;
	z-index: 100;
}

header nav {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 20px;
}

header ul {
	list-style: none;
	margin: 0;
	padding: 0;
	display: none; /* Hidden by default */
	flex-direction: column;
	gap: 1rem;
	position: absolute;
	width: 100vw;
	top: 80px;
	left: 0;
	background-color: #f8f8f8;
	padding: 1rem;
	z-index: 1;
}

header ul.open {
	display: flex; /* Show when the menu is open */
}

header li {
	text-align: center;
}

header .line {
	width: 80px;
	margin: 15px auto 0;
	background-color: #bfbfbf;
}

header li a {
	color: #bfbfbf;
	text-decoration: none;
	font: 600 14px 'Lato', sans-serif;
	text-transform: uppercase;
	letter-spacing: 5px;
}

header li.active a {
	color: #202020;
}

header li.active .line {
	background-color: #202020;
}

header .menu-button {
	position: relative;
	width: 20px;
	height: 24px;
	background: none;
	border: none;
	cursor: pointer;
	z-index: 11;
	right: 20px;
}

header .menu-button span {
	position: absolute;
	width: 30px;
	height: 2px;
	background-color: #333;
	transition: transform 0.5s, opacity 0.5s;
}

header .menu-button span:nth-child(1) {
	top: 5%;
}

header .menu-button span:nth-child(2) {
	top: 50%;
	transform: translateY(-50%);
}

header .menu-button span:nth-child(3) {
	bottom: 5%;
}

header .menu-button.open span:nth-child(1) {
	transform: rotate(45deg) translateY(14px);
	width: 35px;
}

header .menu-button.open span:nth-child(2) {
	opacity: 0;
}

header .menu-button.open span:nth-child(3) {
	transform: rotate(-45deg) translateY(-14px);
	width: 35px;
}

/* ----- HERO SECTION ----- */

.hero-image {
	width: 100%;
	opacity: 0; /* Initial opacity for fade-in */
	animation: fadeIn 0.75s ease-in-out 0.5s forwards; /* Faster fade-in effect with delay */
}

.inline {
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 0; /* Initial opacity for fade-in */
	animation: fadeIn 0.75s ease-in-out 1.25s forwards; /* Faster fade-in effect with delay */
}

.heroSection .line {
	height: 1px;
	width: 100%;
	margin: 0 30px;
	background-color: #edd5bd;
	opacity: 0; /* Initial opacity for fade-in */
	animation: fadeIn 1s ease-in-out 2s forwards; /* Faster fade-in effect with delay */
}

.hero-section-content {
	margin: 10px;
	opacity: 0; /* Initial opacity for fade-in */
	animation: fadeIn 1s ease-in-out 1s forwards; /* Faster fade-in effect with delay */
}

.hero-section-content * {
	margin: -5px;
}

/* Keyframes */
@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

/* ----- INVITE SECTION ----- */

.invite-section {
	margin: 80px 20px 40px;
}

.invite-section .name {
	font-weight: 600;
	font-size: 16px;
	text-transform: uppercase;
	line-height: 30px;
}

.invite-section p {
	letter-spacing: 5px;
}

/* ----- COUNTDOWN SECTION ----- */

.countdown-item {
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	margin: 60px 0;
	opacity: 0; /* Initially hidden */
	transform: translateY(20px); /* Slightly offset for a subtle effect */
	transition: opacity 1s ease-out, transform 1s ease-out; /* Smooth fade and slide-up */
}

.countdown-item.visible {
	opacity: 1; /* Fully visible */
	transform: translateY(0); /* Reset offset */
}

.countdown-item:nth-of-type(2n) {
	flex-direction: row;
}

.countdown-section img {
	width: 250px;
	height: 380px;
}

.countdown-unit {
	display: flex;
	flex-direction: column;
	width: 50%;
}

.countdown-number {
	font-size: 36px;
}

.countdown-uom {
	font: 500 22px 'Sorts Mill Goudy', serif;
	text-transform: uppercase;
}

/* ----- OUR STORY SECTION ----- */

.ourStory-section {
	margin-top: 40px;
}

.ourStory-section p {
	width: 240px;
	margin: 20px auto;
	letter-spacing: 0px;
}

/* ----- VENUE SECTION ----- */

.venue-image {
	margin: 20px 0 40px;
	width: 100%;
}

.venue-section p {
	margin: 0 20px;
}

hr {
	width: 80px;
	margin-top: 40px;
	background-color: #202020;
	border-radius: 100%;
}

/* ----- ACCOMMODATION ----- */

.accommodation-section {
	margin: 40px 0;
}

.accommodation-image {
	width: 100%;
	margin: 20px 0 40px;
}

.accommodation-section p {
	margin: 20px;
}

.accommodation-section hr {
	width: 80px;
	margin-top: 40px;
	background-color: #202020;
}

.accommodation-section iframe {
	border: none;
	border-radius: 15px;
	margin-top: 20px;
}

/* ----- DETAILS SECTION ----- */

.details-section p {
	margin: 0 20px;
	letter-spacing: 5px;
}

.smallCaps {
	text-transform: lowercase;
	font-size: 24px;
	margin: 0 5px;
}

p.event-item {
	font-weight: 300;
	margin-top: 20px;
}

p.event-place {
	font-weight: 600;
	text-transform: uppercase;
	margin-top: 10px;
}

.details-section hr {
	border-color: #edd5bd;
	background-color: #edd5bd;
	width: 90%;
	margin: 40px auto;
}

/* ----- DRESS CODE ------ */

.color-dots {
	display: flex;
	justify-content: center;
	margin: 40px;
}

.color-dot {
	width: 40px;
	height: 40px;
	border-radius: 100%;
	margin: 0 10px;
}

.color-dot.one {
	background-color: #96deae;
}

.color-dot.two {
	background-color: #ff66ff;
}

.color-dot.three {
	background-color: #ffe9d5;
}
.color-dot.four {
	background-color: #ec6a66;
}

/* ----- GIFTS SECTION ----- */

.gifts-section {
	margin-top: 40px;
}

.gifts-section p {
	margin: 20px;
}

/* ----- FAQ SECTION ----- */

.faq-section {
	padding: 0 20px;
}

.lowercase {
	text-transform: lowercase;
}

.question-content {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: 1px solid #edd5bd;
	border-left: none;
	border-right: none;
	padding: 20px 0;
	text-align: start;
}

.question-content p {
	font-weight: 600;
	text-transform: uppercase;
	max-width: 360px;
}

.answer {
	text-align: start;
	letter-spacing: 0px;
	margin: 20px 0;
}

/* ----- CONTACT SECTION ----- */

.contact-section {
	padding: 0 20px;
}

.contact-links {
	display: flex;
	justify-content: center;
}

.whatsapp-link {
	margin: 30px;
	font: 600 16px 'Lato', sans-serif;
	text-decoration: none;
	text-transform: uppercase;
	letter-spacing: 5px;
	color: #202020;
	display: flex;
	align-items: center;
}

.icon {
	margin-right: 10px;
	font-size: 20px;
}

/* ----- PHOTO GALLERY ----- */

.gallery {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 10px;
	padding: 0 10px;
	margin: 40px 0;
}

.gallery img {
	width: 170px;
	margin: 0 auto;
}

/* ----- FOOTER ----- */

footer {
	height: 80px;
	background-color: #efefef;
	align-content: center;
}

footer a {
	color: #202020;
}

/* ----- RSVP SECTION ----- */

.rsvp-section p {
	margin: 20px;
}
